/* .card-no-footer {
    height: calc(100vh - 144px);
    overflow-y: auto;
} */


.content-vh-no-footer {
    height: calc(100vh - 144px);
}


.toasts-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 90;
  }
  
  .toast {
    border: 2px solid transparent;
    background-color: #fafafa;
    border-radius: 4px;
    max-width: 480px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .2);
    margin-top: 16px;
    display: flex;
    position: relative;
    cursor: pointer;
  }
  
  .toast__text {
    padding: 16px 24px;
    line-height: 1.4;
  }
  
  .toast__close-btn {
    border: none;
    background-color: transparent;
    font-size: 16px;
    margin-top: 8px;
    margin-right: 8px;
    cursor: pointer;
  }
  
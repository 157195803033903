@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-border-orange {
  text-shadow: 
    1px 1px 0 #6b1300, 
    -1px -1px 0 #6b1300,
    1px -1px 0 #6b1300,
    -1px 1px #6b1300;
}

.text-border-green {
  text-shadow: 
    1px 1px 0 #333300, 
    -1px -1px 0 #333300,
    1px -1px 0 #333300,
    -1px 1px #333300;
}

input {
  all: unset;
  background: #FBF8EF !important;
  color: #333300;
  border: 1px solid #000 !important;
  width: 100%; 
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
}

input[type='checkbox']{
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width: 20px !important;
  height: 20px !important;
  appearance:none;
  border-radius: 4px;
  border: 1px solid rgb(191 35 42 / 90%);
  box-shadow: none;
  font-size: 1em;
}

input[type=checkbox]:checked {
  background: #333300 !important;
}
input[type='checkbox']:checked:after {
  content: '\2713';
  display: block;
  padding-bottom: 4px;
  line-height: 20px !important;
  color: #FBF8EF;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
}

input::placeholder {
  color: #666633;
  opacity: 1;
}

input::-ms-input-placeholder {
color: #666633;
}